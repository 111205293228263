import { template as template_46cf3b55986f4d4ea170b3cca65b6915 } from "@ember/template-compiler";
const FKText = template_46cf3b55986f4d4ea170b3cca65b6915(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
