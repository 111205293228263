import { template as template_1e116001d13d4d569bb0e6188bf7b2d5 } from "@ember/template-compiler";
const FKControlMenuContainer = template_1e116001d13d4d569bb0e6188bf7b2d5(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
